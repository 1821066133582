import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PatientManagement from "../assets/dashboardImages/patientmanagement.jpg";
import Dashboardimg from "../assets/dashboardImages/dashboardimg2.png";
import Demography from "../assets/dashboardImages/demography2.jpg";
import Portability from "../assets/dashboardImages/portability.jpg";
import Entity from "../assets/dashboardImages/entity.jpg";
import Sentiment from "../assets/dashboardImages/sentiment.jpg";
import Symptom from "../assets/dashboardImages/symptom.jpg";
import Service1 from "../assets/img/services-1.webp";


export default function Dashboard() {
  return (
    <section className="text-gray-700 body-font border-t border-gray-200 font-nunito-sans mb-10">
      <br />
      <br />
      <br />
      <div className="container max-w-[1240px] mx-auto">
        {/* Header */}
        <div className="flex flex-col text-center w-full md:mb-10">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to see analytics from conversations?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            Dashboard for your conversational AI analytics
          </h1>
        </div>

        <div className="w-full px-6 md:mt-0 md:w-full text-center mb-8">
          <p className="my-3 text-lg md:text-xl text-left text-gray-700 font-nunito-sans">
            Introducing our groundbreaking LLM model, a convergence of LLM's
            linguistic capabilities and machine learning's predictive analysis,
            designed to grant you control over your data.
            <br />
            <br />
            Safeguarded on dedicated HIPAA-compliant servers, your data remains
            secure while you harness its insights.
          </p>
        </div>

        {/* Main Image */}
        <div className="relative w-full h-[400px] md:h-[600px] overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={Dashboardimg}
            alt="Dashboard"
          />
        </div>

        <div className="w-full px-6 py-4 mt-4 md:mt-0 md:w-full text-center">
          <h2 className="text-2xl md:text-3xl font-bold text-sc1Shade4 font-nunito-sans">
            Combine LLMs with machine learning algorithms
          </h2>
        </div>

        <div className="w-full px-6 md:mt-0 md:w-full text-center mb-8">
          <p className=" text-lg md:text-xl text-left text-gray-700 font-nunito-sans">
            Empower yourself with a platform that not only delivers powerful
            insights but also prioritizes your data ownership and customization
            needs.
            <br />
            Our LLM model represents a fusion of refined language understanding
            and predictive capabilities. It has undergone meticulous training
            using a vast collection of more than 1 million peer-reviewed
            open-source publications, medical regulations, glossaries, and
            documents curated by experienced medical experts.
          </p>
        </div>

        {/* Key Features Section */}
        <div className="container max-w-[1240px] mx-auto">
          <div className="flex flex-col text-center w-full mb-5 md:mb-20">
            <h2 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
              Key Features
            </h2>

            <div className="max-w-screen-xl mx-auto p-6 sm:p-10 md:p-16">
              <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-10">
                <div className="flex flex-col flex-wrap justify-between rounded overflow-hidden shadow-lg">
                  <a href="/blog/bloggeneration">
                    <div className="relative">
                      <img
                        className="w-full"
                        src={Portability}
                        alt="medical chatbot"
                      />
                    </div>
                  </a>
                  <div className="px-6 py-4">
                    <a
                      href="/blog/bloggeneration"
                      target="blank"
                      className="font-semibold text-xl mt-2  mb-2 inline-block text-sc1Shade4 transition duration-500 ease-in-out"
                    >
                      Data Portability
                    </a>
                    <p className="text-gray-500 text-lg">
                      You're in control. Take your data with you if you choose
                      to depart from the platform, ensuring complete ownership.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap justify-between rounded overflow-hidden shadow-lg">
                  <a href="/blog/bloggeneration">
                    <div className="relative">
                      <img
                        className="w-full h-50"
                        src={Demography}
                        alt="medical chatbot"
                      />
                    </div>
                  </a>
                  <div className="px-6 py-4">
                    <a
                      href="/blog/bloggeneration"
                      target="blank"
                      className="font-semibold text-xl mt-2 mb-2 inline-block text-sc1Shade4 transition duration-500 ease-in-out"
                    >
                      Demographic Insights
                    </a>
                    <p className="text-gray-500 text-lg">
                      Understand your audience's demographics, tailoring your
                      strategies based on their preferences and behaviors.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap justify-between rounded overflow-hidden shadow-lg">
                  <a href="/blog/bloggeneration">
                    <div className="relative">
                      <img
                        className="w-full"
                        src={Sentiment}
                        alt="medical chatbot"
                      />
                    </div>
                  </a>
                  <div className="px-6 py-4">
                    <a
                      href="/blog/bloggeneration"
                      target="blank"
                      className="font-semibold text-xl mt-2 mb-2 inline-block text-sc1Shade4 transition duration-500 ease-in-out"
                    >
                      Sentiment Analysis
                    </a>
                    <p className="text-gray-500 text-lg">
                      Keep a finger on your audience's pulse with robust
                      sentiment analysis, accurately gauging their emotions and
                      reactions.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap justify-between rounded overflow-hidden shadow-lg">
                  <a href="/blog/bloggeneration">
                    <div className="relative">
                      <img
                        className="w-full"
                        src={Entity}
                        alt="medical chatbot"
                      />
                    </div>
                  </a>
                  <div className="px-6 py-4">
                    <a
                      href="/blog/bloggeneration"
                      target="blank"
                      className="font-semibold text-xl mt-2 mb-2 inline-block text-sc1Shade4 transition duration-500 ease-in-out"
                    >
                      Named Entity Recognition
                    </a>
                    <p className="text-gray-500 text-lg">
                      Dive into population statistics and conduct frequency
                      analyses, extracting valuable insights for informed
                      decisions.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap justify-between rounded overflow-hidden shadow-lg">
                  <a href="/blog/bloggeneration">
                    <div className="relative">
                      <img
                        className="w-full"
                        src={Symptom}
                        alt="medical chatbot"
                      />
                    </div>
                  </a>
                  <div className="px-6 py-4">
                    <a
                      href="/blog/bloggeneration"
                      target="blank"
                      className="font-semibold text-xl mt-2 mb-2 inline-block text-sc1Shade4 transition duration-500 ease-in-out"
                    >
                      NLP Symptom-Checkers
                    </a>
                    <p className="text-gray-500 text-lg">
                      Empower clinical staff with real-time patient updates,
                      facilitating quicker assessments and superior care.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap justify-between rounded overflow-hidden shadow-lg">
                  <a href="/blog/bloggeneration">
                    <div className="relative">
                      <img
                        className="w-full"
                        src={PatientManagement}
                        alt="medical chatbot"
                      />
                    </div>
                  </a>
                  <div className="px-6 py-4">
                    <a
                      href="/blog/bloggeneration"
                      target="blank"
                      className="font-semibold text-xl mt-2 mb-2 inline-block text-sc1Shade4 transition duration-500 ease-in-out"
                    >
                      Patient Management
                    </a>
                    <p className="text-gray-500 text-lg">
                      Check status of the  patient, their details, conversations, requirements and appointments all at same place.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Customizable Features Section */}
        <div className="container max-w-[1240px] mx-auto">
          <div className="flex flex-col text-center w-full mb-5 md:mb-20">
            <h2 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
              Customizable Features
            </h2>
            <ul className="list-disc text-left text-gray-700 font-nunito-sans pl-6">
              <li>
                FAQ Section: Tailor FAQs to suit your needs, providing
                comprehensive information and assistance.
              </li>
              <li>
                Application Customization: Personalize the platform with your
                brand's logo, chosen brand colors, and unique questions to fit
                your specific requirements.
              </li>
            </ul>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="container max-w-[1240px] mx-auto">
          <div className="flex flex-col text-center w-full mb-5 md:mb-20">
            <h2 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
              FAQs
            </h2>
            <div className="text-left text-gray-700 font-nunito-sans">
              <div className="mb-3">
                <strong>Q: How secure is my data?</strong>
                <p>
                  Your data is hosted on dedicated HIPAA-compliant servers,
                  ensuring top-notch security protocols are in place to protect
                  your information.
                </p>
              </div>
              <div className="mb-3">
                <strong>
                  Q: Can I extract my data if I decide to discontinue using the
                  platform?
                </strong>
                <p>
                  Absolutely. You have complete control over your data. It can
                  be exported or taken with you if you choose to leave the
                  platform.
                </p>
              </div>
              <div className="mb-3">
                <strong>
                  Q: How does the platform benefit clinical staff?
                </strong>
                <p>
                  With NLP-driven symptom-checkers, clinical staff receive
                  real-time updates on patients, aiding in faster assessments
                  and improving the quality of care.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Closing Section */}
      </div>
    </section>
  );
}
