import React from "react";
// import Chatbot from "../assets/img/Chatbot.png";
import Illustration1 from "../assets/illustrations/Illustration-1.png";

export default function Integrate() {
  const handleConnectClick = () => {
    window.open("https://dataspeckle.com/contact.html", "_blank");
  };
  return (
    <div
      className="max-w-[1240px] mx-auto p-3 my-10 md:grid md:grid-cols-2 font-nunito-sans"
      id="healthcare"
    >
      <div className="col-span-1 flex flex-col justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          INTEGRATE AI TO YOUR OWN WEBSITE
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In the modern world, there is an ever-increasing demand for patient
          service. With that comes the development of our unique chatbot
          technology to help meet this need.
          <br />
          <br />
          Our knowledge-based healthcare bots are customizable, plus they
          understand specific information related to healthcare.
        </p>

        <div className="flex justify-center md:justify-start">
          <button
            onClick={handleConnectClick}
            className="w-[40%] md:w-[35%] bg-pcShade0 hover:bg-pcShade5 text-lg md:text-xl font-nunito-sans font-bold text-white p-2 md:p-3 rounded"
          >
            CONNECT
          </button>
        </div>
      </div>
      <div className="mt-6 md:mt-0 md:ml-auto xl:ml-auto col-span-1 md:w-[80%] text-center">
        <img
          src={Illustration1}
          alt="ai transforming healthcare"
          className="hidden md:block inline w-full"
        />
        <img
          src={Illustration1}
          alt="healthcare chatbot"
          className="md:hidden inline w-80 mt-4"
        />
      </div>
    </div>
  );
}
