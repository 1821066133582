import React, { useState } from 'react';
// import 'styles.css'; // Import your Tailwind CSS styles
import './ChatWithURL.css'; // Create a new CSS file for additional styling

const ChatWithURL = () => {
  const [urlInput, setUrlInput] = useState('');
  const [chatInput, setChatInput] = useState('');
  const [websiteName, setWebsiteName] = useState('');
  const [responses, setResponses] = useState([]);
  const [urls, setUrl] = useState([]);

  const displayResponse = (responseData, id) => {
    setResponses((prevResponses) => [...prevResponses, responseData]);
  };
  const displayURL = (responseData, id) => {
    setUrl((prevResponses) => [...prevResponses, responseData]);

  };
  const submitForm = async () => {

    try {
      displayURL("Kindly hold on as we initiate the database creation process. Fetching content will commence shortly. You will get response from the server when the process completes.", 'responseContainer');
      console.log({ url: urlInput });
      const response = await fetch('/ml/effie-mistral/fetch_and_upload_content', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: urlInput }),
      });
      const data = await response.json();

      if (data.length > 0 && data[0].message) {
        const match = data[0].message.match(/(?:http[s]?:\/\/)?([^\/\s]+)(?:\/|$)/);

        if (match) {
          const websiteName = match[1];
          setWebsiteName(websiteName);
        } else {
          console.error('Unable to extract website name from the message.');
        }
      } else {
        console.error('Invalid JSON response format.');
      }
      
      displayURL(data[0].message, 'responseContainer');
    } catch (error) {
      console.error('Error:', error);
      // Handle errors here
    }
  };



  const submitChat = async () => {
    try {
      console.log(chatInput);
      displayResponse("User: " + chatInput, 'chatContainer');

      const response = await fetch('/ml/effie-mistral/doc_chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question:chatInput,
          history: [],
          db_name: urlInput,
        }),
      });

      const data = await response.json();

      if (data.length > 0 && data[0].message) {
        const message = data[0].message;
        displayResponse("AI:    " + message, 'chatContainer');
      } else {
        console.error('Invalid JSON response format.');
        displayResponse('Sorry!', 'chatContainer');
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle errors here
    }
  };

  return (
    <div className="body">
      <header className="bg-gray-800 text-white text-center p-4">
        <h1 className="text-2xl">Chat with URL</h1>
      </header>

      <section className="m-4">
        <div className="mb-4">
          <h2 htmlFor="url">Enter URL of website</h2>
          <input
            type="url"
            id="url"
            name="url"
            value={urlInput}
            onChange={(e) => setUrlInput(e.target.value)}
            required
            className="p-2 border rounded"
          />
          <button type="button" onClick={submitForm} className="bg-gray-800 text-white p-2 rounded cursor-pointer">
            Submit
          </button>
        </div>
        <div id="urlResponseContainer" className="mb-4">
          {urls.map((url, index) => (
            <p key={index}>{url}</p>
          ))}
        </div>
     

        <form
          action="#"
          method="post"
          id="chatForm"
          onSubmit={(e) => {
            e.preventDefault();
            submitChat();
          }}
        >
          <h2 className="mb-4">Ask questions about the content of the website</h2>
          <div id="chatResponseContainer" className="mb-4">
          {responses.map((response, index) => (
            <p key={index}>{response}</p>
          ))}
          </div>
          <input
            type="text"
            id="chatInput"
            name="chatInput"
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
            required
            className="p-2 border rounded"
          />
          <button type="submit" className="bg-gray-800 text-white p-2 rounded cursor-pointer">
            Send
          </button>
        </form>

        <div id="chatContainer" className="mt-8">
          {/* Chat messages will be displayed here */}
        </div>
      </section>
    </div>
  );
};

export default ChatWithURL;
