import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";

import Dentistry from "../assets/industries-served/dentistry.webp";
import Fitness from "../assets/industries-served/fitness.webp";
import Mental from "../assets/industries-served/mental_health.webp";
import Optometry from "../assets/industries-served/optometry.webp";
import Primary from "../assets/industries-served/primary_care.webp";
import Drawing3 from "../assets/illustrations/drawing-3.png";
import Physiotherapy from "../assets/industries-served/physio.webp";

export default function HealthDomains() {
  const navigate = useNavigate();

  const navigateToDentistry = () => {
    navigate("/blog/dentistry");
  };

  const navigateToPhysiotherapy = () => {
    navigate("/blog/physiotherapy");
  };

  const navigateToPrimaryCare = () => {
    navigate("/blog/primarycare");
  };

  const navigateToOptometry = () => {
    navigate("/blog/optometry");
  };

  const navigateToHealthAndFitness = () => {
    navigate("/blog/health");
  };

  const navigateToMentalHealth = () => {
    navigate("/blog/mentalhealth");
  };

  return (
    <section
      className="text-gray-700 body-font border-t border-gray-200 font-nunito-sans"
      id="services"
    >
      <div className="max-w-[1240px] mx-auto p-3 my-7 md:grid grid-cols-1 lg:grid-cols-3 font-nunito-sans">
        <div className="col-span-1 md:w-[90%] text-center">
          <div className="flex flex-col text-center w-full mb-10">
            <h2 className="text-base text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              OUR SERVICES
            </h2>
            <h1 className="text-3xl font-nunito-sans font-bold text-sc1Shade4">
              HEALTH DOMAINS
            </h1>
          </div>
          <div className="md:hidden relative z-10 mx-auto mb-5">
            <Carousel
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={3000}
              swipeable={true}
              emulateTouch={true}
              dynamicHeight={true}
            >
              <div>
                <img
                  src={Optometry}
                  alt="healthcare systems benefit from AI applications"
                  className="img-fluid w-full"
                />
                <div className="absolute bottom-1 right-3">
                  <button
                    onClick={navigateToOptometry}
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <img
                  src={Dentistry}
                  alt="healthcare systems benefit from AI applications"
                  className="img-fluid w-full"
                />
                <div className="absolute bottom-1 right-3">
                  <button
                    onClick={navigateToDentistry}
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <img
                  src={Physiotherapy}
                  alt="logistics can be more effective with machine learning"
                  className="img-fluid w-full"
                />
                <div className="absolute bottom-1 right-3">
                  <button
                    onClick={navigateToPhysiotherapy}
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <img
                  src={Primary}
                  alt="hr-tech benefits from NLP and recommendation algorithms"
                  className="img-fluid w-full"
                />
                <div className="absolute bottom-1 right-3">
                  <button
                    onClick={navigateToPrimaryCare}
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <img
                  src={Fitness}
                  alt="logistics can be more effective with machine learning"
                  className="img-fluid w-full"
                />
                <div className="absolute bottom-1 right-3">
                  <button
                    onClick={navigateToHealthAndFitness}
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <img
                  src={Mental}
                  alt="hr-tech benefits from NLP and recommendation algorithms"
                  className="img-fluid w-full"
                />
                <div className="absolute bottom-1 right-3">
                  <button
                    onClick={navigateToMentalHealth}
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </Carousel>
          </div>
          <img
            src={Drawing3}
            alt=""
            className="hidden md:block inline w-full"
          />
          <img src={Drawing3} alt="" className="md:hidden inline w-80" />
        </div>

        <div className="hidden md:block relative container col-span-2 max-w-[1240px] mx-auto mt-4">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
            <div className="col">
              <button
                className="focus:outline-none"
                onClick={navigateToDentistry}
              >
                <img
                  src={Dentistry}
                  alt="Dentistry"
                  className="img-fluid w-full"
                />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
            </div>
            <div className="col">
              <button
                className="focus:outline-none"
                onClick={navigateToPhysiotherapy}
              >
                <img
                  src={Physiotherapy}
                  alt="Physiotherapy"
                  className="img-fluid w-full"
                />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
            </div>
            <div className="col">
              <button
                className="focus:outline-none"
                onClick={navigateToPrimaryCare}
              >
                <img
                  src={Primary}
                  alt="Primary Care"
                  className="img-fluid w-full"
                />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
            </div>
          </div>
          <br />
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
            <div className="col">
              <button
                className="focus:outline-none"
                onClick={navigateToOptometry}
              >
                <img
                  src={Optometry}
                  alt="Optometry"
                  className="img-fluid w-full"
                />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
            </div>
            <div className="col">
              <button
                className="focus:outline-none"
                onClick={navigateToHealthAndFitness}
              >
                <img src={Fitness} alt="Fitness" className="img-fluid w-full" />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
            </div>
            <div className="col">
              <button
                className="focus:outline-none"
                onClick={navigateToMentalHealth}
              >
                <img src={Mental} alt="Mental" className="img-fluid w-full" />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
