import React from "react";
import { useEffect } from "react";
import heroVideo from "../assets/videos/hero_sm3.mov"
// import Doctor from "../assets/img/doctor5.jpg";
// import Effie from "../assets/img/effie.png";
// import Effie2 from "../assets/img/effie-logo-3.png";
// import Scan from "../assets/videos/scan.mp4";
// import Dentist from "../assets/videos/dentist.mp4";
// import BannerVideo from "../assets/videos/banner-video.mov";
// import Services1 from "../assets/img/services-1.webp";
// import Services2 from "../assets/img/services-2.webp";
// import Services3 from "../assets/img/services-3.webp";
// import Services4 from "../assets/img/services-4.jpg";
// import dalle21 from "../assets/img/dalle2-1.jpeg";


export default function Banner() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleConnectClick = () => {
    window.open("https://dataspeckle.com/contact.html", "_blank");
  };



  return (
    <div className="relative w-full py-[50px]">
      {/* <img
        src={Doctor}
        alt="Background"
        className="absolute top-0 left-0 w-full h-full object-cover z-10 brightness-75"
      /> */}

      {/* <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover z-[-10] brightness-75"
        src={BannerVideo}
      /> */}

      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover z-[-10]"
        src={heroVideo}
      />
      <div className="absolute inset-0 bg-sc1Shade0 z-[-10] opacity-60"></div>

      {/* <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover z-[-10] brightness-75"
        src={Scan}
      ></video>
      <div className="absolute inset-0 bg-blue-600 z-[-10] opacity-50"></div> */}

      <div className="relative max-w-[1240px] z-20 my-[100px] mx-auto text-center font-bold">
        <h2 className="text-white font-nunito-sans  text-4xl  md:text-6xl">
          <p className="md:mt-5 md:mb-3">Want to help develop conversational</p>
          <p className="md:mt-5 md:mb-5">AI for your clinic?</p>
        </h2>
        <div className="md:mt-5 mt-4 text-lg md:text-2xl text-gray-300 font-nunito-sans">
          EXPLORE IF AI IS THE RIGHT TOOL FOR YOU
        </div>
        <br />
        <button onClick={handleConnectClick} className="bg-pcShade0 hover:bg-pcShade5 uppercase text-base text-white p-3 w-auto rounded font-nunito-sans">
          Connect
        </button>
        <button
            className="bg-pcShade0 hover:bg-pcShade5 uppercase text-base text-white p-3 w-auto rounded font-nunito-sans inline ml-3"
            onClick={() => {
              if (window.Calendly) {
                window.Calendly.initPopupWidget({
                  url: "https://calendly.com/manishmahato257/15min",
                });
              }
            }}
          >
            Schedule Meeting
          </button>
      </div>
    </div>
  );
}
