import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Copyright from '../../components/Copyright';
import TermGen from './TermGen';

export default function Terms() {
  return (
    <>
        <Navbar />
        <TermGen />
        <Footer />
        <Copyright />
    </>
  )
}
