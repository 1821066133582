import React, { useState, useEffect } from "react";
import "./SpellCheck.css"; // Import the CSS file for styling

const SpellingCorrection = () => {
  const [inputSentence, setInputSentence] = useState('');
  const [correctedSentence, setCorrectedSentence] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setInputSentence(e.target.value);
  };

  const handleCorrectButton = async () => {
    // Mock API call (replace with your actual API endpoint)
    console.log(inputSentence);
    setLoading(true);

    try {
      const response = await fetch('/ml/spell_checker', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sentence: inputSentence }),
      });

      if (response.ok) {
        const data = await response.json();
        setCorrectedSentence(data[0].response);

      } else {
        console.error('API request failed');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
        <div>
            <div id="sentence-input">
                <textarea
                    id="spell-check-text-area"
                    placeholder="Enter a sentence with spelling errors"
                    value={inputSentence}
                    onChange={handleInputChange}
                />
                <br />
                <br />

                <button id="spell-check" onClick={handleCorrectButton} disabled={loading}>
                    Correct Spelling
                </button>
                <br />
                <br />
                <br />
            </div>
            {loading && <p id="loading">Loading ..... </p>}
            <div id="sentence-output">
                {correctedSentence && (
                    <div>
                    <h2>Corrected Sentence:</h2>
                    <p id="corrected-sentence">{correctedSentence}</p>
                    </div>
                )}
            </div>
        </div>
    </div>
  );
};

export default SpellingCorrection;
