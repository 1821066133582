import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import "./DocChat.css"; // Import the CSS file for styling
import axios from 'axios';


const DocChat = () => {
  const [file, setFile] = useState(null);
  const [fileContent, setFileContent] = useState("");
  const [dbName, setDbName] = useState("");
  const [context, setContext] = useState("");
  const [chatInput, setChatInput] = useState("");
  const [responses, setResponses] = useState([]);
  const [uploadResponse, setUploadResponse] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);



  // const handleFileChange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   setFile(selectedFile);
  // };


  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
};

  const handleDbNameChange = (e) => {
    const name = e.target.value;
    setDbName(name);
  };

  useEffect(() => {
    // Add any useEffect logic if needed
  }, [uploadResponse]);

  // const handleFileUpload = async () => {
  //   if (file && dbName) {
  //     try {
  //       const content = await readFileContent(file);
  //       const response = await sendApiRequest(dbName, content);
  //       console.log(response);
  //       displayUploadResponse(response[0]['message'])
  //     } catch (error) {
  //       console.error("Error reading file content:", error);
  //       displayUploadResponse(error)

  //       // Handle errors if needed
  //     }
  //   }
  // };
  const handleFileUpload = async () => {
    try {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await axios.post('/ml/effie-mistral/uploadfile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log(response.data);
        setDbName(response.data[0]['message'].split("added to").pop().trim());
        displayUploadResponse(response.data[0]['message'])

    } catch (error) {
        console.error('Error uploading file:', error);
        displayUploadResponse(error)

    }
};


  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target.result;
        resolve(content);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };

  const sendApiRequest = async (dbName, fileContent) => {
    try {
      const apiUrl = "/ml/effie-mistral/upload_content";
      const requestData = {
        content: fileContent,
        source: dbName,
      };
  
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      console.log("API Response:", data);
  
      return data; // Return the data so it can be used in handleFileUpload
    } catch (error) {
      console.error("Error sending API request:", error);
      throw error; // Re-throw the error to be caught in handleFileUpload
    }
  };
  
  const displayResponse = (responseData) => {
    setResponses((prevResponses) => [...prevResponses, responseData]);
  };

  const displayUploadResponse = (responseData) => {
    setUploadResponse((prevResponses) => [...prevResponses, responseData]);
  };

  const submitChat = async () => {
    try {
      console.log(dbName);
      console.log(responses);

      displayResponse({ role: "user", content: chatInput });
      setChatInput("")
      const response = await fetch("/ml/effie-mistral/query_reformulation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: chatInput,
          history: responses,
          db_name: dbName,
        }),
      });

      const data = await response.json();

      if (data.length > 0 && data[0].message) {
        const message = data[0].message;
        setContext(data[0]["context"])
        console.log(context)
        console.log(data[0]["context"]);
        displayResponse({ role: "assistant", content: message });
        console.log(responses);
      } else {
        console.error("Invalid JSON response format.");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
  };

  return (
    <div className="DocChat-container">
      <div className="DocChat-upload-section">
        <h2 className="DocChat-heading">Upload a file to chat with</h2>
        <Form className="DocChat-form">
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label className="DocChat-form-label">
              Upload Text File
            </Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange}
              className="DocChat-form-input"
            />
          </Form.Group>

          {/* <Form.Group controlId="dbName" className="mb-3">
            <Form.Label className="DocChat-form-label">Database Name</Form.Label>
            <Form.Control
              type="text"
              onChange={handleDbNameChange}
              value={dbName}
              className="DocChat-form-input"
            />
          </Form.Group> */}
        </Form>
        <div className="mb-4">
            {uploadResponse.map((response, index) => (
              <p key={index} >
                {response}
                <br></br>
              </p>
            ))}
          </div>
        <button
          onClick={handleFileUpload}
          className="DocChat-button"
        >
          Upload
        </button>

        <div id="doc_source"> Context used: {context}</div>
      </div>

      <div className="DocChat-chat-container">
        <form
          action="#"
          method="post"
          id="chatForm"
          onSubmit={(e) => {
            e.preventDefault();
            submitChat();
          }}
        >
          <h2 className="mb-4">Ask questions about the content of file</h2>
          <div id="chatResponseContainer" className="mb-4">
            {responses.map((response, index) => (
              <p key={index} className={index % 2 === 0 ? 'text-right' : 'text-left'}>
                {response.content}
                <br></br>
              </p>
            ))}
          </div>

          <input
            type="text"
            id="chatInput"
            name="chatInput"
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
            required
            className="p-2 border rounded"
          />
          <button
            type="submit"
            className="bg-gray-800 text-white p-2 rounded cursor-pointer"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default DocChat;
