import React from 'react';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import AIinEducation from './AIinEducation';

export default function Blog() {
  return (
    <>
        <Navbar />
        <AIinEducation />
        <Footer />
        <Copyright />
    </>
  )
}