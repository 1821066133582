import React from 'react';


export default function TermGen() {
   
  
    return (
      <>
        <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
          <div className="col-span-2 flex flex-col justify-center md:w-full">
            <h1 className="uppercase text-sc1Shade4 text-3xl md:text-4xl font-bold my-2 font-nunito-sans">
              Terms of Service
            </h1>
            <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              Privacy
            </h2>
            <p className="my-1 md:my-3 text-base text-justify">
              Your privacy is incredibly important to us. Any information that we collect will only be used in ways for which you have consented. This Privacy Policy governs the manner in which Effie collects, uses, maintains and discloses information collected from users (each, a "User") of the Effie website ("Site"). This privacy policy applies to the Site and all products and services offered by Effie. 
            <br /> <br/> </p>    
            
            <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              Personal identification information
            </h2>
            <p className="my-1 md:my-3 text-base text-justify">
               We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users
              visit our site, register on the site, place an 			order, subscribe to the newsletter, respond to a survey, fill out a form, 
              and in connection with other activities, services, features or resources we make available on our Site. 
              Users may be asked for, as appropriate, name, email address, credit card information. Users may, however, visit our Site 
              anonymously. We will collect personal identification information from Users only if they voluntarily submit such information
              to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging
              in certain Site related activities. Effie does not intentionally collect information from children under the age of
               thirteen (13).      
            <br /> <br/> </p>
            
            <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              Identification information
            </h2>
            <p className="my-1 md:my-3 text-base text-justify">
               We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.    
            <br /> <br/> </p>
            
             <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              Web browser cookies
            </h2>
            <p className="my-1 md:my-3 text-base text-justify">
              Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly. 
            <br /> <br/> </p>
            
            <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              How we use collected information
            </h2>
            <p className="my-1 md:my-3 text-base text-justify">
              Effie may collect and use Users personal information for the following purposes:

              <li class="list-inside">To improve customer service</li>
              Information you provide helps us respond to your customer service requests and support needs more efficiently.

              <li class="list-inside">To personalize user experience</li>
              We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.

              <li class="list-inside">To improve our Site</li>
              We may use feedback you provide to improve our products and services.

              <li class="list-inside"> To process payments</li>
              We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.

              <li class="list-inside"> To run a promotion, contest, survey or other Site feature</li>
              To send Users information they agreed to receive about topics we think will be of interest to them.

              <li class="list-inside">To send periodic emails</li>
              We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, they may do so by contacting us via our Site.
              
            <br /> <br/> </p>
            
            <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              How we protect your information
            </h2>
            <p className="my-1 md:my-3 text-base text-justify">
              We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site. Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures. Effie uses the services of third party vendors for storage, network and technical services. Personally identifying information may be transferred outside of your home country as part of our provision of services to you. When required by law (under governmental order, warrant or subpoena), or when Effie believes that disclosure is reasonably necessary to protect the rights of Effie or third parties, personally identifying information may be disclosed. Effie reserves to right to publish correspondence and feedback sent to us in order to help us respond to the correspondence or to help other users. Should Effie be acquired or merged with another organization, your information may be transferred. We will notify you if your information is to be subject to a different privacy policy. Should Effie cease trading or file for bankruptcy, information held about you will not be transferred to a third party. 
            <br /> <br/> </p>
            
            <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              Sharing your personal information
            </h2>
            <p className="my-1 md:my-3 text-base text-justify">
              We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above. 
            <br /> <br/> </p>
            
            <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              Changes to this privacy policy
            </h2>
            <p className="my-1 md:my-3 text-base text-justify">
              Effie has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications. 
            <br /> <br/> </p>
            
            <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              Your acceptance of these terms
            </h2>
            <p className="my-1 md:my-3 text-base text-justify">
              By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes. 
            <br /> <br/> </p>
            
            <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              Contacting us
            </h2>  
            <p className="my-1 md:my-3 text-base text-justify">
              If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us 
            <br /> <br/> </p>
            
          </div>
        </div>
  
      </>
    );
}
