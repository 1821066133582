import React from "react";
import { useEffect } from "react";
// import Service2 from "../../../assets/industries-served/dentistry.webp";
import Service2 from "../../../assets/img/services-2.webp";

export default function AIinEducation() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            AI in Education
          </h1>
          <p className="my-1 md:my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
            In the ever-evolving landscape of education, the feedback loop is
            not just a tool for improvement—it's the heartbeat of progress.
            Today, the transformative force of artificial intelligence (AI) is
            reshaping how educators gather, analyze, and everage feedback to
            enhance the learning experience. In this blog, we'll journey through
            the world of AI in education feedback, unraveling the significant
            impact it brings. From revolutionizing education with AI's
            capabilities to the indispensable role of data, personalized
            learning through machine learning algorithms, and ethical
            considerations in this tech-driven era, AI is shaping the future of
            education feedback.
            <br />
            <br />
            <div className="md:hidden mb-3">
              <img
                src={Service2}
                alt="healthcare chatbot"
                className="md:hidden inline w-100"
              />
            </div>
            {/* <br />
            <br />
            This collaborative approach saves time and allows bloggers to focus
            more on editing, structuring, and polishing the generated content. */}
          </p>
        </div>
        <div className="mt-6 md:mt-13 md:ml-auto xl:ml-auto col-span-1 md:w-[60%] text-center">
          <img
            src={Service2}
            alt="ai transforming healthcare"
            className="hidden md:block inline w-full"
          />
        </div>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          The Transformative Role of AI in Education
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          AI is not a mere addition to education; it's a game-changer. It
          personalizes learning, identifies gaps in knowledge, and helps
          educators tailor their teaching methods to suit individual student
          needs, ultimately revolutionizing the educational experience.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Data: Nurturing the Future of Learning
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Data is the lifeblood of AI in education. It's not just about
          collecting data; it's about harnessing it effectively. Comprehensive,
          high-quality data fuels AI models, making them smarter and more
          effective in providing personalized feedback to students.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Some Machine Learning Algorithms for Personalized Education
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Machine learning algorithms like recommendation systems, natural
          language processing, and reinforcement learning are indispensable in
          delivering personalized education. These algorithms analyze data to
          tailor content, learning paths, and assessments for each student.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          From Classrooms to Cyberspace: AI in Education Delivery
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          AI isn't confined to traditional classrooms; it extends into the
          digital realm. Online learning platforms use AI to provide real-time
          feedback, track progress, and adapt content, ensuring that learning is
          not bound by time or place.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Ethical Considerations in AI-Enhanced Education
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          As AI takes a central role in education, it raises important ethical
          considerations. Balancing the benefits of AI with privacy, fairness,
          and transparency is essential to ensure that education remains
          equitable and secure.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Conclusion: Shaping the Future of AI in Education
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In conclusion, AI is a powerful tool that is reshaping education
          feedback. It personalizes learning, improves educational outcomes, and
          empowers educators to make data-driven decisions. However, it's
          crucial to wield this power responsibly, considering the ethical
          implications and ensuring that education remains accessible to all. As
          AI continues to evolve, it promises to make education more
          personalized, adaptable, and effective, paving the way for a brighter
          future of learning for students worldwide. AI's role in education
          feedback is not just a glimpse of the future; it's a beacon guiding us
          toward a more inclusive and empowered education system.
        </p>

      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
