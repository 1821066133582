import React from "react";
import { useEffect } from 'react';
import DentistryImage from "../../../assets/industries-served/dentistry.webp";

export default function Dentistry() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            AI in Dentistry
          </h1>
          <p className="my-1 md:my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
            In the vast and ever-evolving landscape of healthcare, dentistry
            often stands as a beacon of resilience, quietly working to safeguard
            our oral health and overall well-being. Yet, it's a field that has
            not shied away from embracing cutting-edge technology, and one of
            its most remarkable allies in this endeavor is the integration of
            chatbots and artificial intelligence (AI).
            <br />
            <br />
            <div className="md:hidden mb-3">
              <img
                src={DentistryImage}
                alt="healthcare chatbot"
                className="md:hidden inline w-100"
              />
            </div>
            These digital marvels, which at first glance might seem like science
            fiction brought to life, are, in reality, on the front lines of a
            quiet revolution. They're reshaping the very foundations upon which
            modern oral healthcare is built, propelling it into a future where
            dental appointments are not just mundane routines but rather
            seamlessly integrated experiences.
            {/* <br />
            <br />
            This collaborative approach saves time and allows bloggers to focus
            more on editing, structuring, and polishing the generated content. */}
          </p>
        </div>
        <div className="mt-6 md:mt-13 md:ml-auto xl:ml-auto col-span-1 md:w-[50%] text-center">
          <img
            src={DentistryImage}
            alt="ai transforming healthcare"
            className="hidden md:block inline w-full"
          />
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <p className="text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In this age of digital transformation, the synergy between dentists
          and AI-driven chatbots is proving to be a boon for both the dental
          industry and its patients, promising brighter smiles and healthier
          lives. Together, they're navigating a paradigm shift that transcends
          traditional notions of dentistry. This is more than just a fusion of
          technology and healthcare; it's a reimagining of what oral health
          means in the 21st century. In the contexts that follow, we'll explore
          how these sophisticated digital companions are reshaping the patient
          experience, streamlining administrative tasks, and enhancing the
          quality of care. So, fasten your seatbelts, as we embark on a journey
          into the captivating world where the precision of technology meets the
          artistry of dentistry, transforming every dental visit into an
          opportunity for better health and well-being.
        </p>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Improved Appointment Scheduling
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Gone are the days of waiting on hold or navigating complex automated
          phone systems to book a dental appointment. With AI-driven chatbots,
          patients can now schedule appointments easily through a user-friendly
          interface. Chatbots can answer questions about available time slots,
          required documentation, and even provide reminders about upcoming
          appointments, making the entire process more efficient and convenient
          for both patients and dental staff.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Personalized Patient Care
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          AI systems in dentistry can analyze patient data, including medical
          histories and treatment records, to offer tailored recommendations and
          treatment plans. These systems can also provide patients with
          personalized dental care tips, such as reminders to floss, brush, or
          attend regular check-ups. This level of personalized care not only
          enhances patient outcomes but also promotes better overall oral
          health.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Remote Consultations
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Especially relevant in the era of telehealth, AI-powered chatbots
          facilitate remote consultations. Patients can seek advice, share
          images or descriptions of dental issues, and receive initial
          assessments from their dental professionals. This not only saves time
          for both patients and dentists but also allows for quicker responses
          to urgent dental concerns.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Enhanced Patient Education
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Understanding one's oral health is crucial for maintaining it. AI
          chatbots can provide patients with information about common dental
          procedures, oral hygiene practices, and even post-treatment care
          instructions. This empowers patients to take better care of their oral
          health and make informed decisions about their dental treatments.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Streamlined Administrative Tasks
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Dental practices can benefit greatly from AI chatbots when it comes to
          handling administrative tasks. These bots can manage patient records,
          insurance claims, and appointment scheduling, reducing the
          administrative burden on dental staff. This, in turn, allows dental
          professionals to focus more on patient care.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Early Diagnosis and Preventive Measures
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          AI algorithms are adept at identifying patterns and anomalies in
          medical data. In dentistry, AI can assist in the early detection of
          oral health issues such as cavities, gum disease, or oral cancer. By
          identifying problems at an early stage, dental professionals can
          provide timely intervention, leading to better outcomes for patients.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          24/7 Accessibility
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Dental emergencies can happen at any time. AI chatbots are available
          around the clock to answer questions, provide guidance during
          emergencies, and offer reassurance to worried patients. This 24/7
          accessibility ensures that patients receive the support they need when
          they need it.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In conclusion, chatbots and AI are ushering in a new era of dentistry,
          making oral healthcare more accessible, efficient, and
          patient-centric. By streamlining administrative tasks, enhancing
          patient education, and enabling early diagnosis and preventive
          measures, these technologies are improving the overall quality of care
          in the dental field. As technology continues to advance, we can expect
          even more innovations that will further elevate the standard of oral
          healthcare for patients worldwide. So, the next time you visit your
          dentist, don't be surprised if an AI chatbot is there to assist in
          providing you with the best possible dental experience.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
