import React from "react";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PersonalizedCare from "../assets/illustrations/personalizedCare.png";
import AppointmentScheduling from "../assets/illustrations/appointmentScheduling.png";
import CostEffective from "../assets/illustrations/costEffectiveHealthCare.png";
import EfficientTriage from "../assets/illustrations/EfficientTriage.png";
import SeamlessIntegration from "../assets/illustrations/seamlessIntegration.png";
import Privacy from "../assets/illustrations/privacy.png";
// import Service1 from "../assets/img/services-1.webp";
// import Service2 from "../assets/img/services-2.webp";
// import Service3 from "../assets/img/services-3.webp";

import medicalIllustration from "../assets/illustrations/new-illustration-2.png";

export default function ClinicalSupport() {
  const navigate = useNavigate();

  const navigateToAppointmentScheduling = () => {
    navigate("/blog/appointmentscheduling");
  };

  const navigateToCostEffective = () => {
    navigate("/blog/costeffective");
  };

  const navigateToEfficientTriage = () => {
    navigate("/blog/efficienttriage");
  };

  const navigateToPersonalizedCare = () => {
    navigate("/blog/personalizedcare");
  };

  const navigateToPrivacyConfidentiality = () => {
    navigate("/blog/privacyconfidentiality");
  };

  const navigateToSeamlessIntegration = () => {
    navigate("/blog/seamlessintegration");
  };
  // const handleConnectClick = () => {
  //   window.open("https://dataspeckle.com/contact.html", "_blank");
  // };
  return (
    <section className="text-gray-700 body-font border-t border-gray-200 font-nunito-sans">
      <div className="max-w-[1240px] mx-auto p-1 my-7 md:grid grid-cols-1 lg:grid-cols-3 font-nunito-sans">
        <div className="col-span-1 md:w-[110%] text-center mt-12">
          <div className="flex flex-col text-center w-full mb-6">
            <h2 className="text-base text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              WHY USE AI?
            </h2>
            <h1 className="text-3xl md:text-4xl font-nunito-sans font-bold text-sc1Shade4">
              CLINICAL SUPPORT USING MACHINE LEARNING
            </h1>
          </div>
          <img
            src={medicalIllustration}
            alt=""
            className="hidden md:block inline w-full"
          />
          <img
            src={medicalIllustration}
            alt=""
            className="md:hidden inline w-80"
          />
        </div>

        <div className="md:block md:w-[80%] relative container col-span-2 max-w-[1240px] mx-auto mt-4">
          <div className="flex flex-wrap m-1">
            <div
              className="flex-1 m-2 relative p-1 bg-center bg-cover rounded-md "
              // style={{ backgroundImage: `url(${Service1})` }}
            >
              <div className="absolute border border-black shadow-lg rounded top-0 left-0 w-full h-full  opacity-90 rounded-md"></div>
              <div className="relative flex flex-col h-full p-6 rounded-md">
                <div className="flex items-center mb-3">
                  <div className="w-10 h-10 mr-3 inline-flex items-center justify-center rounded-full bg-pcShade0 text-white flex-shrink-0">
                    <img
                      width="35"
                      src={PersonalizedCare}
                      alt="Personalized Care"
                      className="img-fluid"
                    />
                  </div>
                  <h2 className="text-pcShade0 text-xl font-nunito-sans font-bold title-font">
                    Personalized Care
                  </h2>
                </div>
                <div className="flex-grow">
                  <p className="text-black leading-relaxed text-lg font-nunito-sans">
                    Personalized and convenient healthcare support available
                    24/7
                  </p>
                </div>
                <div>
                  <button
                    onClick={navigateToPersonalizedCare}
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-base text-pcShade0 p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div
              className="flex-1 m-2 relative p-1 bg-center bg-cover rounded-md"
              // style={{ backgroundImage: `url(${Service2})` }}
            >
              <div className="absolute border border-black shadow-lg rounded top-0 left-0 w-full h-full bg-transparent  opacity-90 rounded-md"></div>
              <div className="relative flex flex-col h-full p-6 rounded-md">
                <div className="flex items-center mb-3">
                  <div className="w-10 h-10 mr-3 inline-flex items-center justify-center rounded-full bg-pcShade0 text-white flex-shrink-0">
                    <img
                      width="35"
                      src={EfficientTriage}
                      alt="Efficient Triage"
                      className="img-fluid"
                    />
                  </div>
                  <h2 className="text-pcShade0 text-xl font-nunito-sans font-bold title-font">
                    Efficient Triage
                  </h2>
                </div>
                <div className="flex-grow">
                  <p className="text-black leading-relaxed text-lg font-nunito-sans">
                    Immediate assistance and reduced waiting times, effective
                    resource allocation
                  </p>
                </div>
                <div>
                  <button
                    onClick={navigateToEfficientTriage}
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-base text-pcShade0 p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap m-1">
            <div
              className="flex-1 m-2 relative p-1 bg-center bg-cover rounded-md"
              // style={{ backgroundImage: `url(${Service3})` }}
            >
              <div className="absolute border border-black shadow-lg rounded top-0 left-0 w-full h-full bg-transparent opacity-90 rounded-md"></div>
              <div className="relative flex flex-col h-full p-6 rounded-md">
                <div className="flex items-center mb-3">
                  <div className="w-10 h-10 mr-3 inline-flex items-center justify-center rounded-full bg-pcShade0 text-white flex-shrink-0">
                    <img
                      width="35"
                      src={AppointmentScheduling}
                      alt="Appointment Scheduling"
                      className="img-fluid"
                    />
                  </div>
                  <h2 className="text-pcShade0 text-xl font-nunito-sans font-bold title-font">
                    Appointment Scheduling
                  </h2>
                </div>
                <div className="flex-grow">
                  <p className="text-black leading-relaxed text-lg font-nunito-sans">
                    Streamlined Appointment Management
                  </p>
                  <div>
                    <button
                      onClick={navigateToAppointmentScheduling}
                      className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-base text-pcShade0 p-2 rounded font-nunito-sans"
                    >
                      Learn More
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex-1 m-2 relative p-1 bg-center bg-cover rounded-md"
              // style={{ backgroundImage: `url(${Service1})` }}
            >
              <div className="absolute border border-black shadow-lg rounded top-0 left-0 w-full h-full bg-transparent opacity-90 rounded-md"></div>
              <div className="relative flex flex-col h-full p-6 rounded-md">
                <div className="flex items-center mb-3">
                  <div className="w-10 h-10 mr-3 inline-flex items-center justify-center rounded-full bg-pcShade0 text-white flex-shrink-0">
                    <img
                      width="35"
                      src={SeamlessIntegration}
                      alt="Seamless Integration"
                      className="img-fluid"
                    />
                  </div>
                  <h2 className="text-pcShade0 text-xl font-nunito-sans font-bold title-font">
                    Seamless Integration
                  </h2>
                </div>
                <div className="flex-grow">
                  <p className="text-black leading-relaxed text-lg font-nunito-sans">
                    Enhanced Brand Image on the platform of your choice
                  </p>
                </div>
                <div>
                  <button
                    onClick={navigateToSeamlessIntegration}
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-base text-pcShade0 p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap m-1">
            <div
              className="flex-1 m-2 relative p-1 bg-center bg-cover rounded-md"
              // style={{ backgroundImage: `url(${Service2})` }}
            >
              <div className="absolute border border-black shadow-lg rounded top-0 left-0 w-full h-full bg-transparent opacity-90 rounded-md"></div>
              <div className="relative flex flex-col h-full p-6 rounded-md">
                <div className="flex items-center mb-3">
                  <div className="w-10 h-10 mr-3 inline-flex items-center justify-center rounded-full bg-pcShade0 text-white flex-shrink-0">
                    <img
                      width="35"
                      src={CostEffective}
                      alt="Cost Effective"
                      className="img-fluid"
                    />
                  </div>
                  <h2 className="text-pcShade0 text-xl font-nunito-sans font-bold title-font">
                    Cost-Effective Healthcare
                  </h2>
                </div>
                <div className="flex-grow">
                  <p className="text-black leading-relaxed text-lg font-nunito-sans">
                    Automating processes and reducing the need for in-person
                    visits
                  </p>
                </div>
                <div>
                  <button
                    onClick={navigateToCostEffective}
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-base text-pcShade0 p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div
              className="flex-1 m-2 relative p-1 bg-center bg-cover rounded-md"
              // style={{ backgroundImage: `url(${Service3})` }}
            >
              <div className="absolute border border-black shadow-lg rounded top-0 left-0 w-full h-full bg-transparent opacity-90 rounded-md"></div>
              <div className="relative flex flex-col h-full p-6 rounded-md">
                <div className="flex items-center mb-3">
                  <div className="w-10 h-10 mr-3 inline-flex items-center justify-center rounded-full bg-pcShade0 text-white flex-shrink-0">
                    <img
                      width="35"
                      src={Privacy}
                      alt="Privacy"
                      className="img-fluid"
                    />
                  </div>
                  <h2 className="text-pcShade0 text-xl font-nunito-sans font-bold title-font">
                    Privacy and Confidentiality
                  </h2>
                </div>
                <div className="flex-grow">
                  <p className="text-black leading-relaxed text-lg font-nunito-sans">
                    GDPR and HIPAA Compliant
                  </p>
                </div>
                <div>
                  <button
                    onClick={navigateToPrivacyConfidentiality}
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-base text-pcShade0 p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
