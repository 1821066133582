import React from "react";
import { useEffect } from "react";
import DentistryImage from "../../../assets/industries-served/process.png";
export default function ProcessAutomation() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            AI in Process Automation
          </h1>
          <p className="my-1 md:my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
            In an era defined by rapid technological advancements, the synergy
            between artificial intelligence (AI), data, and automation has
            opened new doors for businesses seeking efficiency, productivity,
            and innovation.
            <br />
            <br />
            <div className="md:hidden mb-3">
              <img
                src={DentistryImage}
                alt="healthcare chatbot"
                className="md:hidden inline w-100"
              />
            </div>
            This triumvirate is reshaping the way organizations operate,
            optimize processes, and make data-driven decisions. In this blog, we
            will explore how AI, data, and automation come together to drive
            transformative change in the business world.
            {/* <br />
            <br />
            This collaborative approach saves time and allows bloggers to focus
            more on editing, structuring, and polishing the generated content. */}
          </p>
        </div>
        <div className="mt-6 md:mt-13 md:ml-auto xl:ml-auto col-span-1 md:w-[40%] text-center">
          <img
            src={DentistryImage}
            alt="ai transforming healthcare"
            className="hidden md:block inline w-full"
          />
        </div>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          The Foundation of Automation: Data Requirements
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Data, often referred to as the "new oil," is the lifeblood of
          automation. Without the right data, the full potential of AI-driven
          automation cannot be realized. The first step in this journey is
          understanding and harnessing the power of data. Businesses need to
          collect, process, and manage data effectively to fuel their automation
          initiatives. From customer data to operational metrics, data forms the
          foundation upon which automation stands.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Transforming Business Processes with AI
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          AI is the driving force behind the automation revolution. Its ability
          to process vast amounts of data at lightning speed, identify patterns,
          and make predictions has led to groundbreaking changes in the way
          businesses handle their processes. Tasks that once required hours of
          manual work can now be accomplished in seconds. AI-driven automation
          is reshaping industries like finance, healthcare, manufacturing, and
          more. It's not just about saving time and resources; it's about
          enhancing accuracy and enabling innovation.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Enabling Automation: Key ML Algorithms
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Machine Learning (ML) algorithms play a pivotal role in automation.
          These algorithms, designed to learn from data and improve over time,
          are the brains behind AI-driven automation systems. Supervised
          learning algorithms, which are used for predictive tasks, enable
          businesses to make informed decisions based on historical data.
          Unsupervised learning algorithms, on the other hand, help identify
          hidden patterns and anomalies within datasets. Reinforcement learning
          allows for adaptive automation, where systems learn from feedback to
          improve their performance.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Addressing Challenges and Ethical Concerns
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          While the potential of AI-driven automation is immense, it's not
          without its challenges and ethical considerations. Automation may lead
          to job displacement, raising questions about the future of the
          workforce. Ensuring the ethical use of AI, protecting data privacy,
          and addressing bias in algorithms are paramount concerns.
          Organizations must strike a balance between technological progress and
          responsible, ethical practices to harness the full benefits of
          automation.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          A Recap and Vision for AI-Driven Automation
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In conclusion, AI, data, and automation are reshaping the business
          landscape in unprecedented ways. By leveraging data effectively and
          implementing AI-driven automation powered by ML algorithms,
          organizations can streamline processes, boost productivity, and
          enhance decision-making. However, it is imperative that these
          advancements are made responsibly, with a keen eye on addressing
          challenges and ethical concerns.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          As we look to the future, the vision for AI-driven automation is one
          of continued growth and innovation. It's a world where repetitive
          tasks are automated, freeing up human potential for creative and
          strategic endeavors. It's a world where data-driven insights drive
          businesses to new heights of success. It's a world where ethical
          considerations guide the development and deployment of AI
          technologies.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          The potential is immense, and with the right approach, AI, data, and
          automation will continue to revolutionize the way we work and live,
          ushering in a new era of efficiency, productivity, and progress.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
