import React from "react";
import { useEffect } from "react";
// import DentistryImage from "../../../assets/industries-served/dentistry.webp";

export default function SeamlessIntegration() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            Seamless Integration with AI
          </h1>
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <p className="text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Artificial Intelligence (AI) is not just a buzzword; it's a
          technological revolution that has transformed industries across the
          board. In the world of business, healthcare, finance, and more, the
          integration of AI is not a matter of if but when. At DataSpeckle,
          we've made it our mission to ensure that this integration is seamless,
          accessible, and beneficial to everyone. In this blog, we'll explore
          how we're making the future happen today through the seamless
          integration of AI.
        </p>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Why Seamless Integration Matters
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Seamless integration of AI is not just a matter of convenience; it's a
          strategic imperative. AI has the potential to enhance decision-making,
          automate tasks, and deliver insights that were previously
          unimaginable. However, the true value of AI can only be realized when
          it seamlessly blends into existing workflows and processes.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          AI-Powered Solutions Tailored to Your Needs
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          At DataSpeckle, we understand that one size does not fit all. Our
          approach to AI integration is grounded in a deep understanding of your
          unique business or industry needs. We work closely with our clients to
          develop AI-powered solutions that seamlessly integrate with their
          existing systems and processes. Whether you're in healthcare, finance,
          logistics, or any other sector, we tailor our AI solutions to fit like
          a glove.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          User-Friendly Interfaces
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          We believe that AI should empower, not confuse. That's why our AI
          solutions feature user-friendly interfaces that make interaction and
          integration a breeze. Our goal is to ensure that employees and
          stakeholders can harness the power of AI without the need for advanced
          technical skills.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Scalability and Flexibility
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Businesses evolve, and so should your AI solutions. Our offerings are
          designed to scale with your organization's growth and adapt to
          changing circumstances. Whether you need to process more data, serve
          more customers, or expand into new markets, our AI solutions are built
          for flexibility.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Continuous Support and Improvement
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          AI integration is not a one-time event; it's an ongoing journey. We
          provide continuous support to monitor the performance of our AI
          systems, identify areas for improvement, and ensure that your AI
          solutions stay up to date with the latest advancements in AI
          technology.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Security and Compliance
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          We understand that data security and compliance are non-negotiable.
          Our AI integration strategies are designed with these principles in
          mind. We adhere to the highest standards of data security and ensure
          that our AI solutions comply with industry-specific regulations.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Conclusion: Shaping a Seamless Future with AI
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          The future is bright, and AI is at the heart of it. At DataSpeckle,
          we're committed to shaping a future where AI seamlessly integrates
          into your business, your processes, and your life. We believe that the
          true power of AI lies in its ability to enhance, simplify, and
          transform the way we work and live. Together, let's embrace the future
          and make seamless AI integration a reality today.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
