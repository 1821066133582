import React, { useState } from 'react';
import "./StreamText.css"
function StreamText() {
  const [query, setQuery] = useState('');
  const [data, setData] = useState('');
//   https://diosol.com/ml/effie-mistral/query-stream?query=${query}
  const fetchData = async () => {
    try {
      const response = await fetch(`https://diosol.com/ml/effie-mistral/chat_gguf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body :  JSON.stringify({
          "history": [
             
          ],
          "question": query,
          "client_code": "gAAAAABmH3_iC6vsmpVJ_knZ45LMppM0H_t_WGXgiOeNxV7UAqQ82M6rTzFUnqA21MwFoSNZ6Pzfk2sGo_1A8sH0jCGb1y2KFL5Zgtza-m1ZL2D710mIRDvwrmpo9PIHgel3PcVVPQqP",
          "domain_name": "diosol.com"
      })
      });

      const reader = response.body.getReader();
      let result = '';

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        result += new TextDecoder().decode(value);
        // You might want to parse `result` and update `data` accordingly
        setData(result);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <input
         className="input-field"
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your query..."
        />
        <button className="submit-btn" type="submit">Submit</button>
      </form>
      <div>
        {/* Render your data in the UI */}
        <p>{data}</p>
      </div>
    </div>
  );
}

export default StreamText;
