import React, { useState, useRef } from "react";
import "./Audio.css"; // Import the external stylesheet

const AudioRecorder = () => {
  const mimeType = "audio/wav";
  const mediaRecorder = useRef(null);
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [medicalEntities, setMedicalEntities] = useState([]);
  const [diagnosis, setDiagnosis] = useState([]);

  const [audio, setAudio] = useState(null);
  const [serverResponse, setServerResponse] = useState(null);
  const [recordingMessage, setRecordingMessage] = useState(""); // New state for recording message

  const startRecording = async () => {
    try {
      const streamData = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      setStream(streamData);

      const media = new MediaRecorder(streamData, { type: mimeType });
      mediaRecorder.current = media;
      let localAudioChunks = [];

      media.ondataavailable = (event) => {
        if (typeof event.data === "undefined") return;
        if (event.data.size === 0) return;
        localAudioChunks.push(event.data);
      };

      media.onstart = () => {
        setRecordingMessage("Recording  ..."); // Display recording message
      };

      media.onstop = () => {
        const audioBlob = new Blob(localAudioChunks, { type: mimeType });
        setAudioChunks(localAudioChunks);
        setAudio(URL.createObjectURL(audioBlob));
        setRecordingMessage("Recording stopped ."); // Hide recording message
        sendAudioToServer(audioBlob);
      };

      media.start();
    } catch (error) {
      alert(error.message);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }

  };

  const playRecording = () => {
    if (audio) {
      const audioElement = new Audio(audio);
      audioElement.play();
    }
  };

  const extractMedicalEntities = async (data) => {
    try {
      const response = await fetch('https://diosol.com/ml/notes_medical_entities', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // You may need to include additional headers based on your API requirements
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Handle the response data here if needed
      const responseData = await response.json();
      console.log(responseData);

      setMedicalEntities(responseData)

      console.log('Response Data:', responseData);
    } catch (error) {
      console.error('Error during POST request:', error.message);
    }
  };

  const predictDisease = async (data) => {
    try {
      const response = await fetch('https://diosol.com/ml/database/notes_disease_prediction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // You may need to include additional headers based on your API requirements
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Handle the response data here if needed
      const responseData = await response.json();
      console.log(responseData);
      setDiagnosis(responseData)

      console.log('Response Data:', responseData);
    } catch (error) {
      console.error('Error during POST request:', error.message);
    }
  };

  const sendAudioToServer = (audioBlob) => {
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.wav");

    fetch('https://diosol.com/ml/uploadaudio', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        setServerResponse(result);
        var data = {
          "patient_conversation_id" : 1,
          "client_code" : "code",
          "domain_name" : "diosol",
           "history" : [{"role": "user","content":result}]
    
        }
        console.log(data);
        extractMedicalEntities(data)
        predictDisease(data)

      })
      .catch(error => {
        console.error('Error sending audio to backend:', error);
        setServerResponse({ error: 'Failed to send audio to server' });
      });
  };

  return (
    <div className="chat--container">
          <h1 id="audio-heading">    Transform Your Voice: Record and Transcribe Audio Effortlessly  </h1>
          <br></br>
          <br></br>
      <div className="chat-action-section">
        <div className="chat-microphone-section">


          <button className="btn" onClick={startRecording}>
            Start
          </button>
          <button className="btn" onClick={() => { stopRecording(); playRecording(); }}>
            Stop
          </button>
        </div>
        <br></br>
        <div className="recording-message">{recordingMessage}</div>
        <br></br>

      </div>


      <div className="audio-container">
        {audio ? <audio src={audio} controls></audio> : null}
      </div>

      {serverResponse && (
        <div className="server-response">
          <h2>Server Response:</h2>
          <p id="voice-text">{JSON.stringify(serverResponse, null, 2)}</p>
        </div>
      )}

    <br></br>
    <br></br>
    <br></br>
    {medicalEntities  && medicalEntities.length > 0 && (
    <table>
      <thead>
        <tr>
          <th>Entity Group</th>
          <th>Word</th>
        </tr>
      </thead>
      <tbody>
        {medicalEntities.map((entity, index) => (
          <tr key={index}>
            <td>{entity.entity_group}</td>
            <td>{entity.word}</td>
          </tr>
        ))}
      </tbody>
    </table>
    )}

    <br></br>
    <br></br>
    <br></br>

    {diagnosis  && diagnosis.length > 0 && (
      <h2>Diagnosis
          {diagnosis.map((entity, index) => (
          <p key={index}>
            <span>{entity}</span>
          </p>
        ))}
      </h2>


    )}
    </div>

    
  );
};

export default AudioRecorder;
