import React from "react";
import { useEffect } from "react";
// import DentistryImage from "../../../assets/industries-served/dentistry.webp";

export default function PersonalizedCare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            Personalized Care with AI
          </h1>
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <p className="text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In today's rapidly evolving healthcare landscape, the concept of
          personalized care is gaining significant traction. No longer are we
          content with one-size-fits-all treatment plans; instead, we're
          ushering in an era where healthcare is tailored to the individual. And
          at the heart of this transformation is Artificial Intelligence (AI).
        </p>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Personalized Care in Healthcare with AI
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Personalized care is a patient-centric approach that aims to provide
          tailored treatment plans and interventions based on an individual's
          unique characteristics and needs. This approach is becoming
          increasingly achievable thanks to the integration of AI technologies
          into healthcare systems.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Data Sources and Types for Personalized Healthcare
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          To deliver personalized care, healthcare providers need access to a
          wealth of data. This data comes from various sources and types,
          including electronic health records (EHRs), genomic data, wearable
          devices, and patient-reported information. Collecting and managing
          this data effectively is a crucial step in the journey toward
          personalized care.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Machine Learning Algorithms for Diagnostics and Treatment
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          At the core of AI-driven personalized care are machine learning
          algorithms. These algorithms have the remarkable ability to analyze
          vast datasets and identify patterns that may not be apparent to human
          healthcare providers. They can aid in diagnostics, suggesting
          treatment options, and even predicting patient outcomes with
          remarkable accuracy.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          One example of a machine learning algorithm used in diagnostics and
          treatment is the Random Forest algorithm. In healthcare, Random Forest
          is employed for various tasks, including disease diagnosis and
          prediction. For instance, in predicting the risk of heart disease, the
          algorithm can analyze a patient's medical history, lifestyle factors,
          and genetic data to assess the likelihood of developing cardiovascular
          problems. It does this by creating an ensemble of decision trees, each
          trained on a subset of the data, and then combining their predictions
          to provide a more accurate and robust risk assessment. This approach
          allows healthcare providers to offer personalized guidance and
          interventions to reduce the patient's risk of heart disease based on
          data-driven insights.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Predictive Analytics for Disease Prevention
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Prevention is always better than cure. AI empowers healthcare
          providers to predict disease risks with precision. By analyzing
          patient data, AI can identify high-risk individuals and enable
          proactive interventions, ultimately saving lives and reducing
          healthcare costs.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Personalized Medication and Treatment Plans
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Imagine receiving a medication plan tailored precisely to your needs,
          taking into account your genetics, allergies, and even lifestyle
          factors. AI can make this a reality by personalizing medication and
          treatment plans, ensuring optimal effectiveness and minimizing adverse
          effects.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Continuous Monitoring and Feedback Loop
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          AI doesn't stop at diagnosis and treatment planning. With wearable
          devices and remote monitoring systems, patients can be continuously
          monitored, and AI algorithms can analyze the data in real-time. This
          allows for immediate feedback to both patients and healthcare
          providers, enabling timely adjustments to treatment plans.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          The Future of Personalized Care: Ethical and Regulatory Considerations
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          As AI becomes increasingly integrated into healthcare, ethical and
          regulatory concerns come to the forefront. Patient privacy, data
          security, and the responsible use of AI must be addressed to ensure
          that personalized care benefits all without compromising patient trust
          or safety.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In conclusion, AI is at the forefront of the healthcare revolution,
          offering unprecedented opportunities for personalized care. By
          leveraging vast datasets and powerful machine learning algorithms,
          healthcare providers can deliver treatments and interventions tailored
          precisely to each patient's needs. However, it's vital that we
          navigate this exciting future with careful consideration of ethical
          and regulatory concerns, ensuring that the benefits of personalized
          care are accessible to all while safeguarding patient rights and
          privacy. The era of personalized care with AI has dawned, and it
          promises a brighter and healthier future for all.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
