import React from "react";
import { useEffect } from "react";
// import Service3 from "../../../assets/industries-served/dentistry.webp";
import Service3 from "../../../assets/img/services-3.webp";

export default function HyperAutomation() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            Hyper-automation in Supply Chain
          </h1>
          <p className="my-1 md:my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
            In the fast-paced world of supply chain management, the quest for
            efficiency never ends. Enter hyper automation, a game-changing
            phenomenon powered by artificial intelligence (AI). This blog is
            your gateway into the realm of AI-driven hyper automation in the
            supply chain—a realm where efficiency knows no bounds. From an
            exploration of the transformative power of AI in the supply chain to
            the indispensable role of data, machine learning algorithms, and
            ethical considerations, we'll unravel the intricacies of this
            technological revolution and how it's shaping the future of supply
            chain management.
            <br />
            <br />
            <div className="md:hidden mb-3">
              <img
                src={Service3}
                alt="healthcare chatbot"
                className="md:hidden inline w-100"
              />
            </div>
          </p>
        </div>
        <div className="mt-6 md:mt-13 md:ml-auto xl:ml-auto col-span-1 md:w-[50%] text-center">
          <img
            src={Service3}
            alt="ai transforming healthcare"
            className="hidden md:block inline w-full"
          />
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <p className="text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Supply chain management is a delicate dance of logistics, resources,
          and information. In this dance, efficiency is the holy grail. AI, with
          its capacity for hyper automation, is poised to revolutionize how this
          dance is choreographed.
        </p>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Hyper Automation: The Evolution of Supply Chain Efficiency
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Hyper automation is not just an upgrade; it's a complete evolution of
          supply chain efficiency. It integrates AI and automation technologies
          to optimize processes, reduce human intervention, and increase the
          speed and precision of supply chain operations.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Data: The Fuel for Hyper Automation
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Data serves as the lifeblood of hyper automation. Comprehensive and
          real-time data is essential for AI systems to make informed decisions
          and automate processes effectively. The more data AI has access to,
          the smarter and more efficient it becomes.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          The Mighty Machine Learning Algorithms in Supply Chain
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Machine learning algorithms are the driving force behind AI's
          effectiveness in supply chain management. These algorithms can predict
          demand, optimize routes, detect anomalies, and enhance
          decision-making, leading to more agile and responsive supply chains.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Orchestrating Supply Chain Operations with AI
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          AI orchestrates supply chain operations seamlessly. It monitors
          inventory levels, predicts demand fluctuations, automates order
          processing, and ensures that goods flow smoothly from production to
          delivery, all in real time.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Ethical Considerations in AI-Enhanced Hyper Automation
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          As AI takes the helm in supply chain management, ethical
          considerations emerge. Maintaining transparency, fairness, and
          responsible data handling is crucial to ensure that the benefits of
          automation do not come at the expense of ethical principles.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Measuring Success and Shaping the Future of Supply Chain Hyper
          Automation
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Success in supply chain hyper automation is measured through KPIs that
          encompass efficiency, cost savings, and improved customer
          satisfaction. Looking ahead, AI will continue to evolve, presenting
          new opportunities for optimizing supply chain operations and reshaping
          the future of logistics.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Conclusion
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In conclusion, AI's role in hyper automation is a watershed moment for
          supply chain management. It ushers in an era of unprecedented
          efficiency and agility, where supply chains adapt in real time to
          changing demands and unforeseen challenges. However, as we embrace the
          power of AI, it is vital to do so responsibly, with ethical
          considerations at the forefront. As AI-driven hyper automation
          evolves, supply chains worldwide will become not just more efficient,
          but also more resilient and sustainable, setting the stage for a more
          efficient future of supply chain management.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
