import React from "react";
import Drawing1 from "../assets/illustrations/drawing-1.png";
// import DentalDialogue1 from "../assets/demo/Dental Care dialogue/Dental Care dialogue 1.png";
// import DentalDialogue2 from "../assets/demo/Dental Care dialogue/Dental Care dialogue 2.png";
import DentalDialogue11 from "../assets/demo/Demo1.png";
import DentalDialogue12 from "../assets/demo/Demo2.png";
import Drawing2 from "../assets/illustrations/new-illustration-4-white.png";

export default function Demo() {
  return (
    <section className="text-gray-700 body-font border-t border-gray-200 font-nunito-sans" id="demo">
      <div className="max-w-[1240px] mx-auto p-3 my-7 md:grid grid-cols-1 lg:grid-cols-3 font-nunito-sans">
        <div className="col-span-1 mt-4 md:w-[90%] text-center">
          <div className="flex flex-col text-center w-full md:mb-16">
            <h1 className="sm:text-3xl text-3xl font-nunito-sans font-bold text-sc1Shade4">
              Let us help you excel through cost-effective, patient-centric
              service.
            </h1>
          </div>
          <img src={Drawing1} alt="chatbot demo" className="hidden md:block inline w-full" />
          <img src={Drawing2} alt="generative ai demo" className="md:hidden inline w-80 mt-0" />
        </div>
        <div className="md:hidden mt-4 mb-2 text-center text-2xl font-nunito-sans font-bold text-sc1Shade4">
        Leverage power of LLMs in your interaction
        </div>
        <div className="container col-span-1 max-w-[1240px] mx-auto mt-4">
          <div className="md:ml-5 grid gap-4">
            <div className="col">
              <img
                src={DentalDialogue11}
                alt="healthcare systems benefit from AI applications"
                className="img-fluid w-full"
              />
            </div>
          </div>
        </div>
        <div className="container col-span-1 max-w-[1240px] mx-auto mt-4">
          <div className="md:ml-5 grid gap-4">
            <div className="col">
              <img
                src={DentalDialogue12}
                alt="healthcare systems benefit from LLMs"
                className="img-fluid w-full"
              />
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </section>
  );
}
