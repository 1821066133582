import React from "react";
import { useEffect } from 'react';
import Supply from "../../../assets/industries-served/supply.png";

export default function SupplyChain() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const domain = `{domain}`;
  const industry = `{industry}`;
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            AI in Supply Chain and Risk Management
          </h1>
          <p className="my-1 md:my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
            AI is a powerful tool that can be used to improve supply chain
            efficiency and reduce risk. It can be used to analyze data, predict
            trends, and identify potential risks in the supply chain. AI can
            also be used to optimize processes, such as inventory management and
            logistics. It can also be used to detect fraudulent activities and
            prevent them from happening. AI can also be used to improve customer
            service, by providing personalized recommendations and insights. AI
            can also be used to analyze customer feedback and provide insights
            into their preferences. 
            <br />
            <br />
            <div className="md:hidden mb-3">
              <img
                src={Supply}
                alt="healthcare chatbot"
                className="md:hidden inline w-100"
              />
            </div>
            However, LLMs can accelerate the blog generation process. Bloggers
            can provide prompts or initial ideas to LLMs, which then generate
            relevant and coherent content that can serve as a starting point for
            further refinement.
            {/* <br />
            <br />
            This collaborative approach saves time and allows bloggers to focus
            more on editing, structuring, and polishing the generated content. */}
          </p>
        </div>
        <div className="mt-6 md:mt-13 md:ml-auto xl:ml-auto col-span-1 md:w-[50%] text-center">
          <img
            src={Supply}
            alt="ai transforming healthcare"
            className="hidden md:block inline w-full"
          />
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <p className="text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Since dolly 3b is not among the largest LLMs, we cannot perfectly
          control it via prompt engineering. Blog are a longer sequence of text,
          so a single prompt doesn't generate a detailed blog. Dolly-3b cannot
          include a detailed response as required for blogs. So the steps that
          can be used in blog generation are as follows:
        </p>

      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Automating blog generation with dolly-3b
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Since dolly 3b is not among the largest LLMs, we cannot perfectly
          control it via prompt engineering. Blog are a longer sequence of text,
          so a single prompt doesn't generate a detailed blog. Dolly-3b cannot
          include a detailed response as required for blogs. So the steps that
          can be used in blog generation are as follows:
        </p>
        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Creation of question from prefix with domain and industry as input
        </h2>
        <div className="bg-sc1Shade6">
          <div className="p-4">
            <code className="text-gray-700">
              prefixes =[
              <br />
              &emsp;&emsp;"Understanding {domain} in {industry}",
              <br />
              &emsp;&emsp;"What is role of AI in {domain} for {industry}?",
              <br />
              &emsp;&emsp;"ML and AI use case in {domain} for {industry}?",
              <br />
              &emsp;&emsp;"AI use case in {domain} for {industry}?",
              <br />
              &emsp;&emsp;"What are examples of real world problem solved by AI
              and ML {domain} for {industry}?",
              <br />
              &emsp;&emsp;"What are examples of real world problem solved by
              Computer vision in {domain} for {industry}?",
              <br />
              &emsp;&emsp;"What are examples of real world problem solved by NLP
              in {domain} for {industry}?",
              <br />
              &emsp;&emsp;"Which AI and ML algorithm are suitable to use AI and
              ML for {domain} in {industry}",
              <br />
              &emsp;&emsp;"What kind of data is needed to use AI and ML in{" "}
              {domain} for {industry}?",
              <br />
              &emsp;&emsp;"What are sources of data to use AI and ML {
                domain
              }{" "}
              for {industry}?",
              <br />
              &emsp;&emsp;"How much data is needed to use AI and ML {
                domain
              } for {industry}?",
              <br />
              &emsp;&emsp;"Long term advantage of using AI in {domain} for{" "}
              {industry}?",
              <br />]
            </code>
          </div>
        </div>
        <p className="my-3 mt-4 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          This will generate a set of questions. With these questions as input,
          with a script for commonly asked questions on Google, we can get 3-4
          similar questions asked by people. So 10 questions can yield 35-40
          commonly asked questions.
        </p>
        <h2 className="md:mt-5 text-sc1Shade4 text-lg font-bold md:text-xl my-2 font-nunito-sans">
          2. Filtering most relevant questions
        </h2>
        <p className="my-3 md:mt-4 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Some result from ppa-queries generate queries that are totally
          irrelevant to input. Such questions cause loss of natural flow in blog
          generation and seem out of context.
          <br />
          <br />
          So, removing such questions is crucial. For this, BERT can be used as
          model to generating embedding for base input and result of ppa-queries
          output. The embeddings can then be used to calculate cosine
          similarities.
        </p>

        <div className="bg-sc1Shade6">
          <div className="p-4">
            <code className="text-gray-700">
              sent_1 = ["What is the use of artificial intelligence in pediatric
              dentistry?"]
              <br />
              sent_2 =["Why artificial intelligence is popular?"]
              <br />
              <br />
              cosine_similarity = 0.59
            </code>
          </div>
        </div>
        <p className="my-3 md:mt-4 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Sentence 2 is not much meaningful to generate content for pediatrics
          dentistry. So such questions with low similarity can be discarded.
        </p>

        <div className="bg-sc1Shade6">
          <div className="p-4">
            <code className="text-gray-700">
              sent_1 = ["What is the use of artificial intelligence in pediatric
              dentistry?"]
              <br />
              sent_2 =["What is the role of artificial intelligence in
              dentistry?"]
              <br />
              <br />
              cosine_similarity = 0.94
            </code>
          </div>
        </div>
        <p className="my-3 mt-4 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Sentence 2 is meaningful to generate content for pediatrics dentistry.
          So such questions can be helpful.
        </p>
        <h2 className="md:mt-5 text-sc1Shade4 text-lg font-bold md:text-xl my-2 font-nunito-sans">
          3. Leveraging dolly 3b
        </h2>
        <p className="my-3 md:mt-4 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          We can use a prompt and a question and finally generate content for a
          query. For example:
        </p>
        <div className="bg-sc1Shade6">
          <div className="p-4">
            <code className="text-gray-700">
              Instruction = "Based on given question, generate a content
              suitable for blog"
              <br />
              Query = "What is the role of artificial intelligence in
              dentistry?"
            </code>
          </div>
        </div>

        <p className="my-3 md:mt-4 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          We can combine response for all queries and get a final blog.
        </p>
        <h1 className="text-sc1Shade4 mt-5 md:mt-5 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Improving quality of blog
        </h1>
        <p className="my-3 md:mt-4 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          We can pass a bit of context for the question we give via prompt
          engineering. Context can be collected from Google for the questions we
          generate using ppa-queries script.
          <br />
          <br />
          Another option is to pass some important keywords only instead of
          entire context.
        </p>
        <h2 className="mt-3 text-sc1Shade4 text-lg font-bold md:text-xl my-2 font-nunito-sans">
          Drawbacks of PPA approach
        </h2>
        <p className="my-3 md:mt-4 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Different sections can have semantically similar questions, so in some
          cases, we get content focused on definition for each of the
          paragraphs.
          <br />
          <br />
          1. Example of PPA What is AI in healthcare?
          <br />
          2. What is the definition of AI in healthcare?
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
