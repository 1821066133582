import React from "react";
import { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Copyright from "../../components/Copyright";
import Service1 from "../../assets/img/services-1.webp";
import Service2 from "../../assets/img/services-2.webp";
import Service3 from "../../assets/img/services-3.webp";

export default function BlogList() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <section
        className="my-40 text-gray-700 body-font font-nunito-sans"
        id="insights"
      >
        <div className="container max-w-[1240px] mx-auto mt-5">
          <div className="flex flex-col text-center w-full mb-2">
            <h2 className="text-base text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              INSIGHTS
            </h2>
            <h1 className="uppercase text-3xl font-nunito-sans font-bold text-sc1Shade4">
              generated by Effie
            </h1>
          </div>
          <div className="max-w-screen-xl mx-auto p-6 sm:p-10 md:p-16">
            <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-10">
              <div className="flex flex-col flex-wrap justify-between rounded overflow-hidden shadow-lg">
                <a href="/blog/bloggeneration">
                  <div className="relative">
                    <img
                      className="w-full"
                      src={Service2}
                      alt="medical chatbot"
                    />
                    <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                    <a href="!#">
                      <div className="text-sm absolute top-0 right-0 bg-sc1Shade4 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-sc1Shade0 hover:text-indigo-600 transition duration-500 ease-in-out">
                        <span className="font-bold">17</span>
                        <small>Oct</small>
                      </div>
                    </a>
                  </div>
                </a>
                <div className="px-6 py-4">
                  <a
                    href="/blog/aiinhealthcare"
                    target="blank"
                    className="font-semibold text-xl inline-block text-sc1Shade4 transition duration-500 ease-in-out"
                  >
                    AI in Healthcare
                  </a>
                  <p className="text-gray-500 text-lg">
                    Revolutionizing Diagnostics and Treatment
                  </p>
                </div>
                <div className="relative bottom-2 right-2 px-6 py-4 flex flex-row items-center">
                  <span
                    href="#"
                    className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row items-center"
                  >
                    <svg
                      height="13px"
                      width="13px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style={{ enableBackground: "new 0 0 512 512" }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256
                    c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128
                    c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z"
                          />
                        </g>
                      </g>
                    </svg>
                    <span className="ml-1 text-base text-sc1Shade4">
                      1 hour ago
                    </span>
                  </span>
                </div>
              </div>
              
              <div className="flex flex-col flex-wrap justify-between rounded overflow-hidden shadow-lg">
                <a href="/blog/bloggeneration">
                  <div className="relative">
                    <img
                      className="w-full"
                      src={Service3}
                      alt="medical chatbot"
                    />
                    <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                    <a href="!#">
                      <div className="text-sm absolute top-0 right-0 bg-sc1Shade4 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-sc1Shade0 hover:text-indigo-600 transition duration-500 ease-in-out">
                        <span className="font-bold">15</span>
                        <small>Oct</small>
                      </div>
                    </a>
                  </div>
                </a>
                <div className="px-6 py-4">
                  <a
                    href="/blog/futureofmentalhealth"
                    target="blank"
                    className="font-semibold text-xl inline-block text-sc1Shade4 transition duration-500 ease-in-out"
                  >
                    The Future of Mental Health with AI
                  </a>
                  <p className="text-gray-500 text-lg">
                    AI Chatbots and Virtual Therapists
                  </p>
                </div>
                <div className="relative bottom-2 right-2 px-6 py-4 flex flex-row items-center">
                  <span
                    href="#"
                    className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row items-center"
                  >
                    <svg
                      height="13px"
                      width="13px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style={{ enableBackground: "new 0 0 512 512" }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256
                    c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128
                    c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z"
                          />
                        </g>
                      </g>
                    </svg>
                    <span className="ml-1 text-base text-sc1Shade4">
                      15 hours ago
                    </span>
                  </span>
                </div>
              </div>
              <div className="flex flex-col flex-wrap justify-between rounded overflow-hidden shadow-lg">
                <a href="/blog/bloggeneration">
                  <div className="relative">
                    <img
                      className="w-full"
                      src={Service1}
                      alt="medical chatbot"
                    />
                    <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                    <a href="!#">
                      <div className="text-sm absolute top-0 right-0 bg-sc1Shade4 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-sc1Shade0 hover:text-indigo-600 transition duration-500 ease-in-out">
                        <span className="font-bold">10</span>
                        <small>Oct</small>
                      </div>
                    </a>
                  </div>
                </a>
                <div className="px-6 py-4">
                  <a
                    href="/blog/nutritionalguidance"
                    target="blank"
                    className="font-semibold text-xl inline-block text-sc1Shade4 transition duration-500 ease-in-out"
                  >
                    Nutritional Guidance and Diet Planning
                  </a>
                  <p className="text-gray-500 text-lg">
                    Machine Learning for nutrition and diet
                  </p>
                </div>
                <div className="relative bottom-2 right-2 px-6 py-4 flex flex-row items-center">
                  <span
                    href="#"
                    className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row items-center"
                  >
                    <svg
                      height="13px"
                      width="13px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style={{ enableBackground: "new 0 0 512 512" }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256
                    c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128
                    c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z"
                          />
                        </g>
                      </g>
                    </svg>
                    <span className="ml-1 text-base text-sc1Shade4">
                      5 days ago
                    </span>
                  </span>
                </div>
              </div>
              <div className="flex flex-col flex-wrap justify-between rounded overflow-hidden shadow-lg">
                <a href="/blog/bloggeneration">
                  <div className="relative">
                    <img
                      className="w-full"
                      src={Service1}
                      alt="medical chatbot"
                    />
                    <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                    <a href="!#">
                      <div className="text-sm absolute top-0 right-0 bg-sc1Shade4 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-sc1Shade0 hover:text-indigo-600 transition duration-500 ease-in-out">
                        <span className="font-bold">18</span>
                        <small>July</small>
                      </div>
                    </a>
                  </div>
                </a>
                <div className="px-6 py-4">
                  <a
                    href="/blog/bloggeneration"
                    target="blank"
                    className="font-semibold text-xl inline-block text-sc1Shade4 transition duration-500 ease-in-out"
                  >
                    Blog Generation Leveraging LLM
                  </a>
                  <p className="text-gray-500 text-lg">
                    Revolution in blogs using Machine Learning.
                  </p>
                </div>
                <div className="relative bottom-2 right-2 px-6 py-4 flex flex-row items-center">
                  <span
                    href="#"
                    className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row items-center"
                  >
                    <svg
                      height="13px"
                      width="13px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style={{ enableBackground: "new 0 0 512 512" }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256
                    c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128
                    c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z"
                          />
                        </g>
                      </g>
                    </svg>
                    <span className="ml-1 text-base text-sc1Shade4">
                      8 days ago
                    </span>
                  </span>
                </div>
              </div>
              <div className="flex flex-col flex-wrap justify-between rounded overflow-hidden shadow-lg">
                <a href="/blog/aieducation" target="blank">
                  <div className="relative">
                    <img
                      className="w-full"
                      src={Service2}
                      alt="healthcare chatbot"
                    />
                    <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                    <a href="/blog/aieducation">
                      <div className="text-sm absolute top-0 right-0 bg-sc1Shade4 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-sc1Shade0 hover:text-indigo-600 transition duration-500 ease-in-out">
                        <span className="font-bold">16</span>
                        <small>July</small>
                      </div>
                    </a>
                  </div>
                </a>
                <div className="grow px-6 py-4">
                  <a
                    href="/blog/aieducation"
                    target="blank"
                    className="font-semibold text-xl inline-block text-sc1Shade4 transition duration-500 ease-in-out"
                  >
                    AI in Education
                  </a>
                  <p className="text-gray-500 text-lg">
                    This is how AI can help you learn.
                  </p>
                </div>
                <div className="relative bottom-2 right-2 px-6 py-4 flex flex-row items-center">
                  <span
                    href="#"
                    className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row items-center"
                  >
                    <svg
                      height="13px"
                      width="13px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style={{ enableBackground: "new 0 0 512 512" }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256
                    c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128
                    c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z"
                          />
                        </g>
                      </g>
                    </svg>
                    <span className="ml-1 text-base text-sc1Shade4">
                      13 days ago
                    </span>
                  </span>
                </div>
              </div>
              <div className="flex flex-col flex-wrap justify-between rounded overflow-hidden shadow-lg">
                <a href="/blog/hyperautomation" target="blank">
                  <div className="relative">
                    <img className="w-full" src={Service3} alt="LLM chatbot" />
                    <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                    <a href="/blog/hyperautomation">
                      <div className="text-sm absolute top-0 right-0 bg-sc1Shade4 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-sc1Shade0 hover:text-indigo-600 transition duration-500 ease-in-out">
                        <span className="font-bold">10</span>
                        <small>July</small>
                      </div>
                    </a>
                  </div>
                </a>
                <div className="px-6 py-4">
                  <a
                    href="/blog/hyperautomation"
                    target="blank"
                    className="font-semibold text-xl inline-block text-sc1Shade4 transition duration-500 ease-in-out"
                  >
                    Hyper-automation in Supply Chain
                  </a>
                  <p className="text-gray-500 text-lg">
                    How will AI manage your Industry?
                  </p>
                </div>
                <div className="px-6 py-4 flex flex-row items-center">
                  <span
                    href="#"
                    className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row items-center"
                  >
                    <svg
                      height="13px"
                      width="13px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style={{ enableBackground: "new 0 0 512 512" }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256
                    c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128
                    c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z"
                          />
                        </g>
                      </g>
                    </svg>
                    <span className="ml-1 text-base text-sc1Shade4">
                      Yesterday
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container max-w-[1240px] mx-auto mt-4">
          <div className="flex flex-col text-center w-full mb-5 md:mb-20">
            <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
              want to know more about use cases of ai?
            </h2>
            <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
              design LLM model for your domain using your own data
            </h1>
          </div>
          <div className="flex justify-center items-center">
            <div className="flex flex-wrap mb-5">
              <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
                <input
                  className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                  type="email"
                  placeholder="Enter your Email"
                  defaultValue=""
                />
              </div>
              <div className="flex-2 md:flex-2">
                <button
                  type="submit"
                  className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
                >
                  Send US
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Copyright />
    </>
  );
}
