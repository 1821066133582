import React from "react";

export default function Copyright() {
  return (
    <article className="footer-1 bg-sc1Shade5 container-xl">
      <div className="flex flex-wrap items-center justify-center">
        <div className="w-full text-center text-white">
          <div className="text-base">
            © Copyright 2023 DataSpeckle™
          </div>
        </div>
      </div>
    </article>
  );
}
