import React, { useState, useRef } from "react";
import "./Audio.css"; // Import the external stylesheet

const StreamingAudioRecorder = () => {
  const mimeType = "audio/wav";
  const mediaRecorder = useRef(null);
  const sendIntervalRef = useRef(null); // Ref for the send interval
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);
  const [serverResponse, setServerResponse] = useState([]);

  const startRecording = async () => {
    try {
      const streamData = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      setStream(streamData);

      const media = new MediaRecorder(streamData, { type: mimeType });
      mediaRecorder.current = media;
      let localAudioChunks = [];

      media.ondataavailable = (event) => {
        if (typeof event.data === "undefined") return;
        if (event.data.size === 0) return;
        localAudioChunks.push(event.data);
      };

      media.onstop = () => {
        const audioBlob = new Blob(localAudioChunks, { type: mimeType });
        setAudioChunks(localAudioChunks);
        setAudio(URL.createObjectURL(audioBlob));
        sendAudioToServer(audioBlob);
      };

      media.start();

      // Start sending audio to server at intervals of every 1 second
      sendIntervalRef.current = setInterval(() => {
        
        if (mediaRecorder.current.state === "recording") {
            mediaRecorder.current.stop();
            media.ondataavailable = (event) => {
            if (typeof event.data === "undefined") return;
            if (event.data.size === 0) return;
            localAudioChunks.push(event.data);
          };
      }
        if (localAudioChunks.length > 0) {
          // console.log(localAudioChunks);
          if (localAudioChunks.length < 40){

            const audioBlob = new Blob(localAudioChunks, { type: mimeType });
            sendAudioToServer(audioBlob);
          }
          else {
            var length = localAudioChunks.length
            var chunks = localAudioChunks.slice(length-40,length)
            const audioBlob = new Blob(chunks, { type: mimeType });
            sendAudioToServer(audioBlob);
          }
          media.start();

          // localAudioChunks = []; // Clear chunks after sending
        }
      }, 200);
    } catch (error) {
      alert(error.message);
    }
  };

  const sendAudioToServer = (audioBlob) => {
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.wav");

    fetch('https://diosol.com/ml/uploadaudio', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(result => {
        // console.log(result);
        serverResponse.push(result)
        setServerResponse(serverResponse);
        var data = {
          "patient_conversation_id" : 1,
          "client_code" : "code",
          "domain_name" : "diosol",
           "history" : [{"role":"user","content":serverResponse}]

        }
        // extractMedicalEntities(data)
      })
      .catch(error => {
        console.error('Error sending audio to backend:', error);
        
        serverResponse.push({ error: 'Failed to send audio to server' })

        setServerResponse(serverResponse);
      });
  };
  const extractMedicalEntities = async (data) => {
    try {
      const response = await fetch('https://diosol.com/ml/notes_medical_entities', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // You may need to include additional headers based on your API requirements
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Handle the response data here if needed
      const responseData = await response.json();
      console.log('Response Data:', responseData);
    } catch (error) {
      console.error('Error during POST request:', error.message);
    }
  };
  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      sendAudioToServer(audioBlob);
      clearInterval(sendIntervalRef.current); // Clear the send interval when recording stops
    }
  };

  return (
    <div className="chat--container">
      <div className="chat-action-section">
        <div className="chat-microphone-section">
          <button className="btn" onClick={startRecording}>
            Start
          </button>
          <button className="btn" onClick={() => { stopRecording(); }}>
            Stop
          </button>
        </div>
      </div>
      {/* <div className="audio-container">
        {audio ? <audio src={audio} controls></audio> : null}
      </div> */}
    {serverResponse && (
      <div className="server-response">
        <h2>Last Server Response:</h2>
        {JSON.stringify(serverResponse[serverResponse.length - 1], null, 2)}
      </div>
    )}

    </div>
  );
};

export default StreamingAudioRecorder;
