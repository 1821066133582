import React from "react";
import { useEffect } from "react";
// import DentistryImage from "../../../assets/industries-served/dentistry.webp";

export default function PrivacyConfidentiality() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            Privacy and Confidentiality
          </h1>
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <p className="text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In the digital age, data is the lifeblood of innovation. The advent of
          Artificial Intelligence (AI) has ushered in a new era of
          possibilities, but with great power comes great responsibility. At
          Effie, we understand the paramount importance of privacy and
          confidentiality when it comes to your data. In this blog, we'll delve
          into how we ensure the highest standards of data privacy and
          confidentiality as we harness the transformative potential of AI.
        </p>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Our Commitment to Privacy
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          At Effie, we consider data privacy to be non-negotiable. We
          adhere to stringent privacy standards and take every measure to
          protect your personal and sensitive information. This commitment
          extends across every facet of our AI-powered solutions.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Secure Data Handling
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Data security is at the core of our operations. We employ
          state-of-the-art encryption protocols to safeguard data in transit and
          at rest. Whether it's medical records, financial information, or
          personal data, rest assured that your information is protected from
          unauthorized access.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Ethical Data Usage
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          We believe that ethical data usage is not just a best practice; it's a
          moral imperative. Our AI algorithms are designed to extract insights
          and generate value from data without compromising individual privacy.
          We adhere to all relevant data protection regulations, ensuring that
          your data is treated with the utmost respect and transparency.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Transparent Data Practices
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Transparency is key to building trust. We provide clear and concise
          information about how your data will be used, ensuring that you are
          fully informed about the purposes, risks, and benefits of our
          AI-powered services. We're always available to address any questions
          or concerns you may have about your data.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Anonymization and De-identification
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In cases where data can be effectively used without identifying
          individuals, we prioritize anonymization and de-identification
          techniques. By removing personally identifiable information, we strike
          a balance between data utility and privacy protection.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Continuous Monitoring and Improvement
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Data privacy is an ongoing commitment. We continuously monitor our
          systems, processes, and algorithms to identify and mitigate potential
          privacy risks. We invest in research and development to stay at the
          forefront of privacy-preserving AI technologies.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Regulatory Compliance
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          We understand the complex and evolving landscape of data protection
          regulations. Our practices align with global standards, such as GDPR
          and HIPAA, ensuring that we meet the highest legal and ethical
          standards for data privacy and confidentiality.
        </p>
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Conclusion: Your Data, Your Trust
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          At Effie, we recognize that your trust is earned through our
          actions, not just our words. We are dedicated to ensuring that your
          data remains private and confidential while delivering the benefits of
          AI-driven insights and innovation. As we move forward, you can rely on
          Effie to prioritize your data privacy and confidentiality at
          every step of our journey. Together, we'll unlock the transformative
          potential of AI while safeguarding the values of privacy and trust
          that underpin our digital world.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
