import React from "react";
import { useEffect } from "react";
import DentistryImage from "../../../assets/industries-served/onboarding.png";

export default function CustomerOnboarding() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            Customer Onboarding
          </h1>
          <p className="my-1 md:my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
            In an era defined by digital transformation and heightened customer
            expectations, the process of onboarding customers has evolved
            significantly. Thanks to the integration of artificial intelligence
            (AI), businesses are now revolutionizing customer onboarding, making
            it a seamless, personalized, and efficient experience.
            <br />
            <br />
            <div className="md:hidden mb-3">
              <img
                src={DentistryImage}
                alt="healthcare chatbot"
                className="md:hidden inline w-100"
              />
            </div>
            In this blog, we will explore the transformative impact of AI on the
            customer onboarding journey, delve into the components that make it
            possible, and discuss the ethical considerations as we envision the
            AI-driven future of customer onboarding.
          </p>
        </div>
        <div className="mt-6 md:mt-13 md:ml-auto xl:ml-auto col-span-1 md:w-[50%] text-center">
          <img
            src={DentistryImage}
            alt="ai transforming healthcare"
            className="hidden md:block inline w-full"
          />
        </div>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Revolutionizing Customer Onboarding with AI
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Traditionally, customer onboarding has often been a cumbersome and
          time-consuming process. However, AI is changing the game. It enables
          businesses to redefine onboarding, making it more engaging, efficient,
          and tailored to individual needs. This revolutionary shift starts with
          the streamlining of the onboarding journey.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Streamlining the Onboarding Journey
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          The first step in the AI-driven customer onboarding journey is
          streamlining the process. AI allows for the automation of repetitive
          tasks, reducing paperwork and manual data entry. This means customers
          can complete onboarding faster and with fewer hassles, leaving a
          positive first impression.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Personalized Experiences with AI
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          AI empowers businesses to offer highly personalized onboarding
          experiences. By analyzing customer data, AI algorithms can recommend
          products, services, or features that align with each customer's unique
          needs and preferences. This level of personalization not only enhances
          the onboarding experience but also increases the likelihood of
          customer satisfaction and retention.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Algorithms for Personalized Recommendations
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Key ML algorithms, such as supervised learning, are instrumental in
          making personalized recommendations. These algorithms analyze
          historical data to understand customer behavior and preferences. This
          allows businesses to suggest tailored solutions during onboarding,
          ensuring customers feel valued and understood.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Types of Data Essential for Personalized Customer Onboarding
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          To achieve effective personalization, businesses need access to
          various types of data. This includes customer demographics, purchase
          history, online behavior, and even sentiment analysis from customer
          feedback. The more comprehensive and accurate the data, the better AI
          can tailor the onboarding journey.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Ensuring Ethical Use of AI in Customer Interactions
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          While the benefits of AI in customer onboarding are evident, it is
          crucial to address ethical concerns. Transparency, data privacy, and
          fairness must be at the forefront of AI-driven interactions. Striking
          the right balance between automation and human touch is essential to
          ensure customers' trust in the process.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Envisioning the AI-Driven Future of Customer Onboarding
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Looking ahead, the future of customer onboarding is undeniably
          AI-driven. As AI technologies continue to advance, we can anticipate
          even more sophisticated personalization, faster onboarding processes,
          and improved customer satisfaction. However, it's crucial that
          businesses remain committed to ethical AI practices to build and
          maintain trust with their customers.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In conclusion, AI is revolutionizing customer onboarding by
          streamlining processes, delivering personalized experiences, and
          leveraging powerful ML algorithms. As we envision the AI-driven future
          of customer onboarding, it's clear that this technology will continue
          to reshape the way businesses engage with their customers, making
          first impressions more positive and long-lasting. By embracing AI
          responsibly, businesses can stay at the forefront of the evolving
          customer landscape and deliver exceptional onboarding experiences.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
