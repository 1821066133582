import React from 'react';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import EfficientTriage from './EfficientTriage';

export default function Blog() {
  return (
    <>
        <Navbar />
        <EfficientTriage />
        <Footer />
        <Copyright />
    </>
  )
}