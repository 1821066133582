import React from "react";
import NavbarHome from "../../components/NavbarHome";
import Banner from "../../components/Banner";
import Integrate from "../../components/Integrate";
import HealthDomains from "../../components/HealthDomains";
import Domains from "../../components/Domains";
import Dashboard from "../../components/Dashboard";
import ClinicalSupport from "../../components/ClinicalSupport";
import Demo from "../../components/Demo";
import Insights from "../../components/Insights";
import ContactUS from "../../components/ContactUS";
import Footer from "../../components/Footer";
import Copyright from "../../components/Copyright";

export default function Home() {
  return (
    <>
      <NavbarHome />
      <Banner />
      <Integrate />
      <HealthDomains />
      <Domains />
      <Demo />
      <ClinicalSupport />
      {/* <Insights /> */}
      <Dashboard />
      <ContactUS />
      <Footer />
      <Copyright />
    </>
  );
}
