import React from "react";

export default function Footer() {
  return (
    <div className="footer-1 bg-sc1Shade5 py-8 sm:py-12 font-nunito-sans">
      <div className="container mx-auto px-4">
        <div className="sm:flex sm:flex-wrap sm:-mx-4 md:py-4">
          <div className="px-4 sm:w-1/2 md:w-1/4 xl:w-1/6 mt-8 md:mt-0 md:ml-40">
            <h5 className="text-2xl font-bold text-white mb-6">About</h5>
            <ul className="list-none footer-links text-lg text-gray-200">

              <li className="mb-2">
                <a
                  href="/terms"
                  target="blank"
                  className="hover:text-white hover:font-bold"
                >
                  Privacy
                </a>
              </li>
              <li className="mb-2">
              <a
                  href="/terms"
                  target="blank"
                  className="hover:text-white hover:font-bold"
                >
                  Terms
                </a>
                
              </li>
            </ul>
          </div>
          <div className="px-4 sm:w-1/2 md:w-1/4 xl:w-1/6 mt-8 md:mt-0">
            <h5 className="text-2xl text-white font-bold mb-6">Help</h5>
            <ul className="list-none footer-links text-lg text-gray-200">
              <li className="mb-2">
                <a href="#contact" className="hover:text-white hover:font-bold">
                  Support
                </a>
              </li>
              <li className="mb-2">
                <a href="https://dataspeckle.com/contact.html" className="hover:text-white hover:font-bold">
                  Help Center
                </a>
              </li>
              {/* <li className="mb-2">
                <a href="#contact" className="hover:text-white hover:font-bold">
                  Contact Us
                </a>
              </li> */}
            </ul>
          </div>
          <div className="px-4 xl:mt-0 sm:w-1/3 xl:w-1/6 sm:mx-auto xl:mt-0 xl:ml-auto">
            <h5 className="text-2xl text-white font-bold mb-6 sm:text-center xl:text-center">
              Stay connected
            </h5>
            <div className="flex sm:justify-center xl:justify-center">
              <a
                href="https://www.linkedin.com/company/dataspeckle/"
                className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 text-white hover:text-white hover:bg-blue-600 hover:border-blue-600"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://twitter.com/DataSpeckle"
                className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-white hover:text-white hover:bg-blue-400 hover:border-blue-400"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/dataspeckle/"
                className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-white hover:text-white hover:bg-gray-600 hover:border-gray-600"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>

        {/* <div className="sm:flex sm:flex-wrap sm:-mx-4 mt-6 pt-6 sm:mt-12 sm:pt-12 border-t">
          <div className="px-4 sm:w-1/2 md:w-1/4 mt-4 md:mt-0 font-nunito-sans">
            <h6 className="font-bold mb-2 text-lg">Address</h6>
            <address className="not-italic mb-4 text-base">
              Chakupat, Lalitpur
              <br />
              Kathmandu, Bagmati 54890
            </address>
          </div>
          <div className="px-4 md:w-1/4 md:ml-auto mt-6 sm:mt-4 md:mt-0">
            <button className="uppercase w-[70%] bg-customBlue text-lg font-bold text-white p-3 rounded font-nunito-sans">
              connect
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
}
